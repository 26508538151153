import { styled, Typography, useMediaQuery } from "@mui/material";
import { MenuItemsInfo } from "../SideBar/SideBar";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { MonitorSvg } from "../../svgIcons";
import React from "react";
import { useTheme } from "@mui/material/styles";

const CATEGORIES = [
  { name: t("mobiles"), icon: "📱", path: "/mobiles" },
  { name: t("laptops"), icon: "💻", path: "/laptops" },
  { name: t("smartGadget"), icon: "⌚", path: "/smart-gadgets" },
  { name: t("audio"), icon: "🎧", path: "/audio" },
  { name: t("gaming"), icon: "🎮", path: "/gaming" },
  { name: t("monitors"), icon: <MonitorSvg />, path: "/tv" },
  { name: t("photo-video"), icon: "📸", path: "/photo-video" },
  // additional
  { name: "Chargers & Cables", icon: "🔌" },
  { name: "Cases & Covers", icon: "📦", path: "tv" },
];

const Root = styled("div")(({ theme }) => ({
  paddingTop: "20px",
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const Item = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  fontSize: "14px",

  "& svg": {
    width: "27px",
    height: "27px",
  },
}));

const CategoryMobilePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm")); // 'sm' corresponds to 600px by default

  return (
    <Root>
      <Container>
        {CATEGORIES.map((item, index) => {
          return (
            <Item
              key={index}
              onClick={() => navigate(item.path)}
              sx={{
                display: "flex",
                "&:hover": {
                  color: "#3281fe",
                },
              }}
            >
              <span
                style={{
                  flex: isMobileScreen ? "10%" : "5%",
                }}
              >
                {item.icon}
              </span>
              <Typography
                sx={{
                  flex: isMobileScreen ? "90%" : "95%",
                }}
              >
                {item.name}
              </Typography>
            </Item>
          );
        })}
      </Container>
    </Root>
  );
};

export default CategoryMobilePage;
