import React, { useState } from "react";
import { Popper, Paper, Button, Fade, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DownArrow, MonitorSvg } from "../../svgIcons";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";

const CategoryPaper = styled(Paper)(({ theme }) => ({
  borderRadius: "16px",
  background: "linear-gradient(to bottom right, #ffffff, #f9f9ff)",
  boxShadow: "0 8px 25px rgba(0,0,0,0.1)",
  overflow: "hidden",
  width: "300px",
  border: "1px solid rgba(0,0,0,0.05)",
}));

const CategoryMenuItem = styled("div")(({ theme }) => ({
  padding: "12px 20px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#333",
  fontWeight: 500,
  "&:hover": {
    "& .menuItemBox": {
      transform: "translateX(10px)",
    },
    backgroundColor: "rgba(50, 129, 254, 0.05)",
    color: "#3281fe",
  },
}));

const MenuItemBox = styled(Box)({
  transition: "all 0.3s ease",
  // paddingLeft: "15px",
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
});

const categories = [
  { name: t("mobiles"), icon: "📱", path: "/mobiles" },
  { name: t("laptops"), icon: "💻", path: "/laptops" },
  { name: t("smartGadget"), icon: "⌚", path: "/smart-gadgets" },
  { name: t("audio"), icon: "🎧", path: "/audio" },
  { name: t("gaming"), icon: "🎮", path: "/gaming" },
  { name: t("monitors"), icon: <MonitorSvg />, path: "/tv" },
  { name: t("photo-video"), icon: "📸", path: "/photo-video" },
  // additional
  { name: "Chargers & Cables", icon: "🔌" },
  { name: "Cases & Covers", icon: "📦", path: "tv" },
];

const CategoryDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const handleCategoryClick = (categoryPath) => {
    setOpen(false);
    navigate(categoryPath);
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        height: "100% !important",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          // borderRadius: "10px",
          border: "1px solid rgb(223, 226, 231);",
          background: open ? "rgba(232, 234, 238, 0.5);" : "#FFF",
          boxShadow:
            "rgb(255, 255, 255) 0px 1px 0px inset, rgba(232, 234, 238, 0.4) 0px -1px 0px inset, rgba(223, 226, 231, 0.5) 0px 1px 2px 0px;",
          borderColor: open ? "rgba(50,129,254,0.3)" : "",
          height: "30px",
          padding: "0px 10px",
          borderRadius: "40px",
        }}
      >
        <Typography
          sx={{
            margin: "0px",
            fontWeight: 300,
            fontSize: "13px",
            // color: "#FFF",
            color: "#3a354",
            // color: "#3281FE",
          }}
        >
          {t("categories")}
        </Typography>
        <DownArrow
          // color={"#3281FE"}
          style={{
            height: "19px",
            width: "19px",
            marginLeft: "auto",
            transition: "transform 0.3s ease",
            transform: open ? "rotate(180deg)" : "",
            marginTop: "1px",
          }}
        />
      </Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        disablePortal
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 2], // [horizontal, vertical] in pixels
            },
          },
        ]}
        style={{
          zIndex: 1000,
          marginTop: "10px",
          width: "300px",
        }}
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            timeout={{
              enter: 300,
              exit: 200,
            }}
          >
            <CategoryPaper elevation={4}>
              {categories.map((category, index) => (
                <CategoryMenuItem
                  key={category.name}
                  onClick={() => handleCategoryClick(category.path)}
                >
                  <MenuItemBox className={"menuItemBox"}>
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        margin: "0px",
                      }}
                    >
                      {category.icon} {category.name}
                    </span>
                    <KeyboardArrowDownIcon
                      style={{
                        transform: "rotate(-90deg)",
                        color:
                          location.pathname === category.path
                            ? "#3281FED9"
                            : "rgba(0,0,0,0.4)",
                        fontSize: 18,
                      }}
                    />
                  </MenuItemBox>
                </CategoryMenuItem>
              ))}
            </CategoryPaper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default CategoryDropdown;
